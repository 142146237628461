import React from 'react'

import { Feature } from '../../components'
import track_care_information from '../../assets/track_care_information.webp'
import care_circle from '../../assets/care_circle.webp'
import care_passport from '../../assets/care_passport.webp'
import expert_resources from '../../assets/expert_resources.webp'
import trends from '../../assets/trends.webp'
import documents from '../../assets/documents.webp'
import './features.css'

const Features = () => {
    return (
        <div className='features'>
            <h1>
                Tools & support <span style={{ fontFamily: 'GTSectraItalic' }}>that matter</span>
            </h1>
            <div className='features-row'>
                <Feature
                    img={track_care_information}
                    title='Track care information'
                    subtitle='Store, track and share symptoms, behaviours, appointments, medications & more'
                    hasImgMask={true}
                />
                <Feature
                    img={care_circle}
                    title='Collaborate with your team'
                    subtitle='Invite family and key professionals to the platform'
                    hasImgMask={true}
                />
            </div>
            <div className='features-row'>
                <Feature
                    img={care_passport}
                    title={`Shareable care passport`}
                    subtitle='Securely share an overview of important information'
                    hasImgMask={true}
                />
                <Feature
                    img={expert_resources}
                    title='Access to expert resources'
                    subtitle='Expertise and resources tailored for you and your family'
                    hasImgMask={false}
                />
            </div>
            <div className='features-row'>
                <Feature
                    img={trends}
                    title='Trends'
                    subtitle='Explore trends, patterns and progress over time'
                    hasImgMask={true}
                />
                <Feature
                    img={documents}
                    title='Documents'
                    subtitle='Upload and securely store, access, and share key documents on the move'
                    hasImgMask={false}
                />
            </div>
        </div>
    )
}

export default Features
