import React from 'react'
import './terms_and_conditions.css'

import { Navbar, Footer } from '../../components'

const TermsAndConditions = () => {
    return (
        <div className='TermsAndConditions'>
            <Navbar />
            <div className='terms_and_conditions'>
                <h1 className='terms_and_conditions-title'>Terms and Conditions</h1>
                <p className='terms_and_conditions-description'>
                    <p className='terms_and_conditions-highlight'>
                        <p>Welcome to Hibi 💚 We're excited to support you on your loved one's health journey. Before diving in, please take a moment to review our Terms and Conditions. Don't worry, we've highlighted the most important bits here:</p>
                        <p><strong>Your Privacy is Paramount.</strong> Safeguarding your personal information is our top priority. We will never sell your data and you are in control of your information, with the ability to delete your account anytime. Learn more in our <a href='https://hibi.health/privacy-policy/'>Privacy Policy</a>.</p>
                        <p><strong>Not a Substitute for Medical Advice.</strong> While Hibi offers helpful tools and resources, we are not a healthcare provider. Our content is informational only and should never replace the guidance of medical professionals. Always consult your doctor with any health concerns.</p>
                        <p><strong>You're in the Driver's Seat.</strong> By creating an account, you're responsible for all activity on your profile. We currently offer a free basic service, with paid upgrades coming soon. We'll keep you posted on any updates.</p>
                        <p><strong>Refunds for Change of Heart.</strong> If you purchase a paid Hibi service and change your mind, just let us know within 14 days for a full refund, no questions asked.</p>
                        <p><strong>The Legal Essentials.</strong> Of course, we have to include some unavoidable legal language too. The full terms outline the nitty-gritty around liability, disputes, changes to terms, and so on. By using Hibi you agree to these terms in their entirety.</p>
                        <p>Our goal is to make your Hibi experience as smooth and rewarding as possible. If you have any questions or feedback, we're always eager to hear from you at <a href='mailto:hello@hibi.health'>hello@hibi.health</a>. Thank you for being part of the Hibi family!</p>
                    </p>
                    &nbsp;
                    <p>
                        <strong>
                            Please read all these Terms and Conditions carefully (as of 28/03/2024).
                        </strong>
                    </p>

                    <p>
                        As we welcome you to the service (the “Service”) provided via the Hibi app
                        (the “App”) available in the Apple App Store and Google Play Store (the
                        “Stores”), make sure these Terms and Conditions (the “Agreement”) contain
                        all that you want and nothing that you are not happy with. This Agreement is
                        legally enforceable without further reference to you.
                    </p>

                    <p>
                        These Terms and Conditions should be read alongside, and in addition to, our
                        Privacy Policy, which can be found at:&nbsp;
                        <a href='https://hibi.health/privacy-policy/'>
                            https://hibi.health/privacy-policy/
                        </a>
                        .
                    </p>

                    <h3>
                        <strong>1.</strong> <strong>Introduction</strong>
                    </h3>

                    <p>
                        This Agreement will apply to the usage of the App by you (the&nbsp;
                        <strong>Customer</strong>&nbsp;or&nbsp;<strong>you</strong>).&nbsp;We
                        are&nbsp;Hibi Health Ltd&nbsp;(“Hibi”) a company registered in&nbsp;England
                        and Wales&nbsp;under number&nbsp;14527962&nbsp;whose registered office is
                        at&nbsp;125-127 Mare Street, London,&nbsp;E8 3RH;&nbsp;(the&nbsp;
                        <strong>Supplier</strong>&nbsp;or&nbsp;<strong>us</strong>&nbsp;or&nbsp;
                        <strong>we</strong>). You can contact us by emailing us at{' '}
                        <a href='mailto:hello@hibi.health'>hello@hibi.health</a> or at{' '}
                        <a href='http://www.hibi.health'>www.hibi.health</a> (the “Website”).
                    </p>

                    <p>
                        These are the terms on which we provide the Service to you. Before signing
                        up to the App, you will be asked to agree to this Agreement by clicking on
                        the checkbox labeled 'I agree to the Terms and Conditions and the Privacy
                        Policy'. If you do not click on the checkbox, you will not be able to
                        complete your sign up.&nbsp;You must at least 18 years old to create an
                        account. If you are a parent or guardian of a dependent who is younger than
                        the legal age of majority in your location of residence, you may accept this
                        Agreement on behalf of the minor dependent, but thereby take full
                        responsibility for their use of the Service.
                    </p>

                    <h3>
                        <strong>2.</strong> <strong>About Hibi</strong>
                    </h3>

                    <p>
                        The Hibi app provides tools and resources to allow you to help you manage
                        and coordinate your loved one's care, including but not limited to: secure
                        document storage and sharing, a digital care passport, a journal for
                        tracking (e.g., mood, symptoms, behaviours) and logging (e.g., medications,
                        appointments), and an expert-led discover section where you can ask
                        questions and access expert resources.
                    </p>

                    <p>
                        We generally offer free basic services and fee-based subscription services
                        to provide additional functionalities. By signing up to the App, you only
                        get the free basic services by default. Fee-based subscription services will
                        be made available later. To stay up to the date, please check the Website or
                        you might be notified through the App when there is an update.
                    </p>

                    <p>
                        <strong>NOT MEDICAL ADVICE: </strong>You agree that if you choose to use the
                        Service, you are solely responsible for deciding how you act in relation to
                        your health. As such you agree that Hibi is not a healthcare provider, and
                        does not provide you with any actual medical advice. No information provided
                        through the Service, the App, or the Website can substitute professional
                        medical advice. PLEASE DO NOT USE HIBI FOR MEDICAL PURPOSES. IN CASE OF ANY
                        HEALTH ISSUES OR CONCERNS FOR YOUR LOVED ONE OR YOURSELF, CONSULT A MEDICAL
                        PROFESSIONAL.
                    </p>

                    <h3>
                        <strong>3.</strong> <strong>Interpretation</strong>
                    </h3>

                    <p>
                        Section headings are inserted for convenience only and shall not affect the
                        interpretation of these Terms and Conditions.
                    </p>

                    <h3>
                        <strong>4.</strong> <strong>Service</strong>
                    </h3>

                    <p>
                        The description of the Service is as set out on the Website. Any description
                        is for illustrative purposes only and there may be small discrepancies in
                        the functionality and all functionality is subject to availability and
                        variability.
                    </p>

                    <p>
                        We are not obliged to accept any registrations and, in our sole
                        determination and discretion, we shall decline the creation and active usage
                        of any and all accounts that are intended to disrupt or falsify the Service
                        or that, in fact, do disrupt or falsify the Service.
                    </p>

                    <p>You may not copy or publish any part of the app or services.</p>

                    <p>
                        By using the App, you give us permission to use your anonymous information
                        for product development and statistical purposes to improve the Service for
                        all users.
                    </p>

                    <p>
                        Subject to your consent to this Agreement and your use of the Service, Hibi
                        hereby grants to you a personal, non-assignable, non-exclusive, limited
                        license (the “License”) to user the software provided to you through the App
                        (the “Software”).
                    </p>

                    <p>
                        You agree that the Software may automatically download and install updates
                        and upgrades from time to time without further notice to you, to improve or
                        enhance the Software and the Service.
                    </p>

                    <p>
                        You agree not to post, upload, publish, submit, store, or transmit any
                        content that: (i) infringes, misappropriates or violates a third party’s
                        patent, copyright, trademark, trade secret, moral rights or other
                        intellectual property rights, or rights of publicity or privacy; (ii)
                        violates, or encourages any conduct that would violate, any applicable law
                        or regulation or would give rise to civil liability; (iii) is fraudulent,
                        false, misleading or deceptive; (iv) is defamatory, obscene, pornographic,
                        vulgar or offensive; (v) promotes discrimination, bigotry, racism, hatred,
                        harassment or harm against any individual or group; (vi) is violent or
                        threatening or promotes violence or actions that are threatening to any
                        person or entity; or (vii) promotes illegal or harmful activities or
                        substances. You further agree not to engage in any activity that interferes
                        with or disrupts the Service (or the servers and networks which are
                        connected to the Service).
                    </p>

                    <h3>
                        <strong>5.</strong> <strong>User Content and Data Protection</strong>
                    </h3>

                    <p>
                        We take your privacy and data protection very seriously and retain all
                        personal information under the Privacy Policy and comply with General Data
                        Protection Regulation. By using the Service, you agree to the collection and
                        use of personal information in accordance with our Privacy Policy.
                    </p>

                    <p>
                        For the purposes of these Terms and Conditions: “Data Protection Laws” means
                        any applicable law relating to the processing of Personal Data, including,
                        but not limited to&nbsp;the GDPR. “GDPR” means the UK General Data
                        Protection Regulation. “Data Owner”, “Data Controller”, “Data Processor”,
                        “Personal Data” and “Processing” shall have the same meaning as in the GDPR.
                    </p>

                    <p>
                        You are the sole Data Owner and retain full ownership of all profile
                        information, data, files, or other materials that you enter, log, or post in
                        the App (the “User Content”). Furthermore, you are the Data Controller, as
                        such you and only you decide to what end or purposes the User Content gets
                        used.
                    </p>

                    <p>
                        We are a Data Processor of the Personal Data we Process in providing the
                        Service to you.
                    </p>

                    <p>
                        Subject to creating an account on the App, your data will be stored locally
                        on your device as well as on our servers so that the Service can used over
                        several devices that are logged in. Your data is securely backed up in the
                        cloud.
                    </p>

                    <p>
                        Throughout your usage of the App, it is solely your responsibility to ensure
                        that any information or specification you provide is accurate.&nbsp;
                    </p>

                    <p>
                        Hibi is not in any way liable for your User Content. We assume no liability
                        for the deletion, corruption, or failure to store any User Content
                        maintained or transmitted through your use of the App other than the
                        liability required by applicable law.
                    </p>

                    <p>We may contact you by using email and you expressly agree to this.</p>

                    <p>
                        For any enquiries or complaints regarding User Content and data protection
                        and privacy, you can email us at:{' '}
                        <a href='mailto:hello@hibi.health'>hello@hibi.health</a>.
                    </p>

                    <h3>
                        <strong>6.</strong> <strong>Withdrawal and Cancellation</strong>
                    </h3>

                    <p>
                        You can withdraw from this Agreement and thereby the Service by either
                        deleting your account or telling us at any time, if you simply wish to
                        change your mind and without giving us a reason, and without incurring any
                        liability.
                    </p>

                    <h3>
                        <strong>7.</strong> <strong>Limitation of Liability</strong>
                    </h3>

                    <p>
                        WE DO NOT ASSUME ANY GUARANTEE OR SPECIFIC LIABILITY AS TO THE SERVICE AND
                        WITH REGARD TO THE PURPOSE OF YOUR USE. IN PARTICULAR, WE ARE NOT
                        RESPONSIBLE IF YOU USE THE SERVICE FOR MEDICAL PURPOSES.
                    </p>

                    <h3>
                        <strong>8.</strong>{' '}
                        <strong>Governing Law, Jurisdiction, and Complaints</strong>
                    </h3>

                    <p>
                        This Agreement (including any non-contractual matters) is governed by the
                        law of England and Wales.
                    </p>

                    <p>
                        Disputes can be submitted to the jurisdiction of the courts of England and
                        Wales or, where the Customer lives in Scotland or Northern Ireland, in the
                        courts of respectively Scotland or Northern Ireland.
                    </p>

                    <p>
                        We try to avoid any dispute, so we deal with complaints as follows: If a
                        dispute occurs customers should contact us to find a solution. We will aim
                        to respond with an appropriate solution within 5 days.
                    </p>

                    <h3>
                        <strong>9.</strong> <strong>Change to these Terms and Conditions</strong>
                    </h3>

                    <p>
                        Hibi Health Ltd&nbsp;reserves the right to change these Terms and Conditions
                        as we may deem necessary from time to time or as may be required by law. Any
                        changes will be immediately posted on the Website and the App and you are
                        deemed to have accepted the terms of the Privacy Policy on your first use of
                        the Service following the alterations.&nbsp;&nbsp;You may contact&nbsp;Hibi
                        Health Ltd&nbsp;by email at&nbsp;
                        <a href='mailto:hello@hibi.health'>hello@hibi.health</a>.
                    </p>

                    <p></p>
                </p>
            </div>
            <Footer />
        </div>
    )
}

export default TermsAndConditions
