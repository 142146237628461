import React from 'react'
import './mission.css'

import { useWindowDimensions, convertRemToPixels } from '../../utils'

const Mission = () => {
    const { width } = useWindowDimensions()
    const lineWidth = width - convertRemToPixels(width <= 550 ? 4.5 : width <= 700 ? 6 : 15)

    return (
        <div className='mission section__padding'>
            <div className='mission-line' style={{ width: lineWidth }} />
            <h1 className='mission-title'>Our mission</h1>
            <p className='mission-text'>
                For every family to have access to the care support they need
            </p>
            <p className='mission-subtext'>
                Hibi supports family and professional caregivers, as well as those managing their own care.
            </p>
        </div>
    )
}

export default Mission
