import React from 'react'
import './partnerships.css'

import { Navbar, Data, Footer, GoTo, Partnering } from '../../components'
import { Values } from '../../containers'

import scale_care_support from '../../assets/scale_care_support.webp'
import empower_families from '../../assets/empower_families.webp'
import collaboration from '../../assets/collaboration.webp'
import reporting from '../../assets/reporting.webp'
import customisable from '../../assets/customisable.webp'
import map from '../../assets/map.webp'
import quality_of_life from '../../assets/quality_of_life.webp'
import recommended from '../../assets/recommended.webp'
import significant_time_savings from '../../assets/significant_time_savings.webp'

const Partnerships = () => {
    return (
        <div className='Partnerships'>
            <Navbar />
            <Partnering />
            <Values
                titleComponent={
                    <h1 className='values-title'>
                        What we <span style={{ fontFamily: 'GTSectraItalic' }}>help</span> with
                    </h1>
                }
                cardImgSources={[scale_care_support, empower_families, collaboration]}
                cardTitles={[
                    'Scalable care support',
                    'Empower families',
                    'Collaboration & information sharing',
                ]}
                cardSubtitles={[
                    'Scale the care support your team provide by providing tailored resources to your population.',
                    `Empower families to manage, coordinate & navigate their loved one's care, as well as their own wellbeing.`,
                    'Facilitate closer collaboration & information sharing between professionals and families.',
                ]}
            />
            <Values
                titleComponent={
                    <h1 className='values-title'>
                        <span style={{ fontFamily: 'GTSectraItalic' }}>Flexible</span> to your needs
                    </h1>
                }
                cardImgSources={[reporting, customisable, map]}
                cardTitles={[
                    'Reporting & population analysis',
                    'Customisable content',
                    'Maps of local services',
                ]}
                cardSubtitles={[
                    'Gain valuable insights with advanced reporting and population analysis for your patients',
                    'Deliver customisable, specific content directly to families within your area.',
                    'Access custom solutions such as administrative portals and interactive maps of local services.',
                ]}
            />
            <Values
                titleComponent={
                    <h1 className='values-title'>
                        Make an <span style={{ fontFamily: 'GTSectraItalic' }}>impact</span>
                    </h1>
                }
                cardImgSources={[quality_of_life, recommended, significant_time_savings]}
                cardTitles={[
                    'Quality of life improvements',
                    'Recommended by professionals',
                    'Significant time savings',
                ]}
                cardSubtitles={[
                    '80% of early access users report quality of life improvements.',
                    'Over 3/4s of care professionals recommend Hibi to families',
                    "Care professionals report 4+ hours per week time savings.",
                ]}
            />
            <Data hasBorderBottom={false} smallerPaddingBottom={true} />
            <GoTo />
            <Footer hasBorderTop={false} />
        </div>
    )
}

export default Partnerships
