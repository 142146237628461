import React, { useState, useEffect } from 'react'

import './hibi_plus.css'

import { Navbar, Footer, LearnMore, QuizForm } from '../../components'

import { customQuizFormEnabled } from '../../utils'

import gradient_background_banner from '../../assets/gradient_background_banner.webp'
import hibi_plus from '../../assets/hibi_plus.webp'
import care_circle_plus from '../../assets/care_circle_plus.webp'
import journal_plus from '../../assets/journal_plus.webp'
import trends_plus from '../../assets/trends_plus.webp'
import chevron_down from '../../assets/chevron_down.webp'
import chevron_right from '../../assets/chevron_right.webp'
import star from '../../assets/star.webp'
import information from '../../assets/information.webp'

const HibiPlus = ({ showQuizInit = false }) => {
    const [showLearnMore, setShowLearnMore] = useState(false);
    const [showQuiz, setShowQuiz] = useState(showQuizInit);
    const [selectedPlan, setSelectedPlan] = useState('Annual');
    const [stripeLink, setStripeLink] = useState('https://buy.stripe.com/00g02Oc3w8dz73adQR');
    const [countryCode, setCountryCode] = useState('gb');

    // Hook to set country code
    useEffect(() => {
        const getCountryFromIPAsync = async () => {
            await fetch('https://ipapi.co/json/')
                .then((result) => result.json())
                .then((response) => setCountryCode(response && response.country_code ? response.country_code.toLowerCase() : 'us'));
        };

        getCountryFromIPAsync();
    }, [])


    // Function to take the user to after they click Is Hibi+ for you button
    const handleBannerButtonPressed = () => {
        if (customQuizFormEnabled) {
            setShowQuiz(!showQuiz)
        } else {
            window.open("https://form.feathery.io/to/FzIN6h", "_blank", "noreferrer");
        }
    }


    const Banner = () => {
        return (
            <div className='hibi-plus-banner'>
                <img className='hibi-plus-banner-image' src={gradient_background_banner} alt='Banner' />
                <div className='hibi-plus-banner-content'>
                    <img className='hibi-plus-logo' src={hibi_plus} alt='Hibi Plus'/>
                    <p className='hibi-plus-banner-title'>Supercharge your <span style={{ fontFamily: 'GTSectraItalic'}}>loved one's care</span> with Hibi Plus</p>
                    <div className='hibi-plus-banner-button' onClick={handleBannerButtonPressed}>
                        <p className='hibi-plus-banner-button-text'>Is Hibi+ for you?</p>
                    </div>
                </div>
            </div>
        );
    };

    const Features = () => {
        const Feature = ({ title, description, imgSrc }) => {
            return (
                <div className='hibi-plus-feature'>
                    <img className='hibi-plus-feature-img' src={imgSrc} alt={title} />
                    <div className='hibi-plus-feature-column'>
                        <p className='hibi-plus-feature-title'>{title}</p>
                        <p className='hibi-plus-feature-description'>{description}</p>
                    </div>
                </div>
            );
        };

        return (
            <div className='hibi-plus-features'>
                <Feature title='Go full circle' description='Invite your care circle to collaborate' imgSrc={care_circle_plus} />
                <Feature title='Comprehensive care journal' description='Unlimited entries & export on-the-go' imgSrc={journal_plus} />
                <Feature title='Track trends' description='Explore patterns & insights' imgSrc={trends_plus} />
            </div>
        );
    };

    const LearnMoreButton = () => {
        return (
            <div className='hibi-plus-learn-more-button' onClick={() => setShowLearnMore(true)}>
                <p className='hibi-plus-learn-more-button-text'>Learn more</p>
                <img className='hibi-plus-learn-more-button-img' alt='Information' src={information} />
            </div>
        )
    }

    const Plans = () => {
        const [currencySymbol, setCurrencySymbol] = useState(null)
        const [annualPrice, setAnnualPrice] = useState(null)
        const [monthlyPrice, setMonthlyPrice] = useState(null)
        const [savingsPercentage, setSavingsPercentage] = useState(null)

        // Hook to set available subscription plans based on location
        useEffect(() => {
            if (countryCode === 'gb') {
                setCurrencySymbol('£')
                setAnnualPrice(59.99)
                setMonthlyPrice(8.99)
            } else if (countryCode === 'us') {
                setCurrencySymbol('$')
                setAnnualPrice(119.99)
                setMonthlyPrice(14.99)
            } else if (countryCode === 'au') {
                setCurrencySymbol('$')
                setAnnualPrice(119.99)
                setMonthlyPrice(14.99)
            } else if (countryCode === 'nz') {
                setCurrencySymbol('$')
                setAnnualPrice(99.99)
                setMonthlyPrice(19.99)
            } else if (countryCode === 'ca') {
                setCurrencySymbol('$')
                setAnnualPrice(79.99)
                setMonthlyPrice(12.99)
            } else if (countryCode === 'tr') {
                setCurrencySymbol('₺')
                setAnnualPrice(2299.99)
                setMonthlyPrice(399.99)
            } else if (['ie', 'fr', 'de', 'es', 'it', 'is', 'gr', 'ge', 'at', 'be', 'hr', 'ee', 'fi', 'lv', 'lt', 'lu', 'mt', 'nl', 'pt', 'rs', 'sk', 'si'].includes(countryCode)) {
                setCurrencySymbol('€')
                setAnnualPrice(69.99)
                setMonthlyPrice(9.99)
            } else if (countryCode === 'in') {
                setCurrencySymbol('₹')
                setAnnualPrice(5900.00)
                setMonthlyPrice(999.00)
            } else {
                setCurrencySymbol('$')
                setAnnualPrice(119.99)
                setMonthlyPrice(14.99)
            }
        }, []);

        // Hook to set the savings percentage
        useEffect(() => {
            if (annualPrice && monthlyPrice) {
                const savingsPercentage_ = ((monthlyPrice * 12 - annualPrice) / (monthlyPrice * 12)) * 100
                setSavingsPercentage(savingsPercentage_)
            }
        }, [annualPrice, monthlyPrice]);

        // Function to update the selected plan
        const selectPlan = (title) => {
            setSelectedPlan(title)
            setStripeLink(title === 'Annual' ? 'https://buy.stripe.com/00g02Oc3w8dz73adQR' : 'https://buy.stripe.com/3csg1M7Ng79vfzGeUU' )
        }

        const Plan = ({ title, price, savingsPercentage }) => {
            return (
                <div className='hibi-plus-plan-column' onClick={() => selectPlan(title)}>
                    <div className={`hibi-plus-plan ${savingsPercentage ? 'hibi-plus-superplan' : ''}`} style={{ border: title === selectedPlan ? '1px solid #1E4C32' : undefined }}>
                        <div className={savingsPercentage ? 'hibi-plus-plan-inner-column' : 'hibi-plus-plan-inner-row'}>
                            <h3 className='hibi-plus-plan-title'>{title}</h3>
                            <p className='hibi-plus-plan-price' style={{ marginTop: savingsPercentage ? undefined : -6}}>{currencySymbol}{price}</p>
                        </div>
                        {savingsPercentage && 
                            <div className='hibi-plus-plan-inner-column' style={{ justifySelf: 'flex-end', alignItems: 'flex-end' }}>
                                <p className='hibi-plus-plan-price-per-month'>{currencySymbol}{(price / 12).toFixed(2)}</p>
                                <p className='hibi-plus-plan-per-month-text'>per month</p>
                            </div>
                        }
                    </div>
                    {savingsPercentage && <SavingsTag percentage={savingsPercentage} />}
                </div>
            );
        };

        const SavingsTag = ({ percentage }) => {
            return (
                <div className='hibi-plus-savings-div'>
                    <p className='hibi-plus-savings-text'>Save {parseFloat(percentage).toFixed(0)}%</p>
                </div>
            );
        };

        return (
            <div className='hibi-plus-plans'>
                <Plan title='Annual' price={annualPrice} savingsPercentage={savingsPercentage} />
                <Plan title='Monthly' price={monthlyPrice} savingsPercentage={null} />
            </div>
        );
    };

    const QuotesCarousel = () => {
        const [shuffledQuotes, setShuffledQuotes] = useState([]);
    
        // Hook to shuffle the quotes to give a random order
        useEffect(() => {
            const quotes = [
                {
                    text: "Medical families: if you're looking for a way to track medication, appointments, home exercises, toileting, etc, etc, etc. all in one easy-to-access place, check out Hibi.",
                    by: 'Camie',
                },
                {
                    text: "I'm a clinical nurse specialist supporting children and your app is amazing!",
                    by: 'Melissa',
                },
                {
                    text: 'In comes the Hibi app, and like a breath of fresh air this app solves many of my family day to day issues.',
                    by: 'Chris',
                },
                {
                    text: "The app has given me a huge boost in my child's safety by allowing me to share my son's medical and educational information with my inner circle.",
                    by: 'Angela',
                },
                {
                    text: 'This app has truly everything you need in one spot. It has made our life so much easier!',
                    by: 'Sara',
                },
            ]
            const shuffled = [...quotes].sort(() => 0.5 - Math.random());
            setShuffledQuotes(shuffled);
        }, []);

        const Quote = ({ text, by, rating = 5 }) => {
            const renderStars = () => {
                const stars = []
                for (let i = 0; i < rating; i++) {
                    stars.push(<img className='hibi-plus-star-img' key={i} alt='Star' src={star} />);
                }
                return stars;
            }

            return (
                <div className='hibi-plus-quote'>
                    <div className='hibi-plus-stars'>{renderStars()}</div>
                    <p className='hibi-plus-quote-text'>{text}</p>
                    <p className='hibi-plus-quote-by'>{by}</p>
                </div>
            );
        };

        return (
            <div className='hibi-plus-quotes-main-container'>
                <p className='hibi-plus-quotes-title'>What our users say 💚</p>
                <div className='hibi-plus-quotes'>
                    {shuffledQuotes.map((quote, index) => <Quote key={index} text={quote.text} by={quote.by} />)}
                </div>
            </div>
        );
    };

    const FAQs = () => {
        const FAQ = ({ question, answer }) => {
            const [open, setOpen] = useState(false);

            return (
                <div className='hibi-plus-faq'>
                    <button className='hibi-plus-faq-button' onClick={() => setOpen(!open)}>
                        <img className='hibi-plus-faq-img' src={open ? chevron_down : chevron_right} alt='Expand' />
                        <p className='hibi-plus-faq-question'>{question}</p>
                    </button>
                    {open && <p className='hibi-plus-faq-answer'>{answer}</p>}
                </div>
            );
        };

        return (
            <div className='hibi-plus-faqs'>
                <p className='hibi-plus-faqs-title'>Frequently asked questions</p>
                <FAQ
                    question='Why is Hibi Plus a paid subscription?'
                    answer={`We founded Hibi to support as many families as possible. Our Hibi Plus subscription allows us to keep Hibi running smoothly, develop improved features, and provide support to families around the world.\n\nWe regularly partner with care organisations who can pay for Hibi Plus on your behalf, providing you and other families with an access code.`}
                />
                <FAQ
                    question='How does the free trial period work?'
                    answer={`You'll get instant access to Hibi Plus membership for 7 days. If you want to keep your subscription, it will automatically start after the free trial period. If not, you can cancel it before the trial is finished. Please note that the free trial is only available if this is the first time you have subscribed to Hibi Plus.`}
                />
                <FAQ
                    question='How do I cancel my subscription?'
                    answer='Your account will be charged for renewal within 24 hours of the end of the current period. At any time, you can access your iOS or Android account settings to manage subscriptions and turn off auto-renewal.'
                />

                <FAQ
                    question='Do members in my care circle also get Hibi Plus?'
                    answer='Yes! And you can add multiple children all within the same subscription.'
                />
                <FAQ
                    question={`What if I can't afford to pay for Hibi Plus?`}
                    answer={
                        <p>
                            You may be able to use care budgets to pay or expense Hibi, such as
                            Disability Living Allowance (DLA) & Personal Independence Payments (PIP)
                            in the UK, the National Disability Insurance Scheme (NDIS) in Australia,
                            or Social Security Disability Insurance (SSDI) and Supplemental Security
                            Income (SSI) in the United States.{'\n\n'}We also suggest sharing Hibi with your care provider
                            and letting them know they can partner with us and provide Hibi Plus
                            access to their network.{'\n\n'}If you're unable to fund a Hibi Plus
                            subscription and believe it would help your family, please reach out to
                            us directly at <a href='mailto:hello@hibi.health' style={{ color: '#1E4C32', fontWeight: 400, cursor: 'pointer' }}>hello@hibi.health</a>.
                        </p>
                    }
                />
            </div>
        );
    };

    return (
        <>
            <Navbar />
            {showLearnMore && <LearnMore setShowLearnMore={setShowLearnMore} />}
            {showQuiz && <QuizForm stripeLink={stripeLink} setShowQuiz={setShowQuiz} />}
            <div className='hibi-plus' style={{ opacity: showLearnMore || showQuiz ? 0.3 : 1.0, filter: showLearnMore || showQuiz ? 'blur(4px)' : undefined }}>
                <Banner />
                <Features />
                <LearnMoreButton />
                <Plans />
                <QuotesCarousel />
                <FAQs />
            </div>
            <Footer />
        </>
    );
};

export default HibiPlus;