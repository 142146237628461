import React, { useState } from 'react'
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'

import { CTA } from '../../components'

import close from '../../assets/discard_button_with_bg.png'

import './navbar.css'

import hibi_logo_with_title from '../../assets/hibi_logo_plus_title.webp'

const Menu = () => (
    <>
        <p style={{ fontWeight: 600 }}>
            <Link className='menu-link' to='/plus'>
                Hibi+
            </Link>
        </p>
        <p>
            <Link className='menu-link' to='/discover'>
                Discover
            </Link>
        </p>
        <p>
            <Link className='menu-link' to='/about'>
                About
            </Link>
        </p>
        <p>
            <Link className='menu-link' to='/partnerships'>
                Partnerships
            </Link>
        </p>
        <p>
            <Link className='menu-link' to='/contact'>
                Contact
            </Link>
        </p>
    </>
)

const Banner = ({ bannerName }) => {
    // Banner flags for special dates
    const isAutismAcceptanceMonth = new Date().getMonth() === 3 ? true : false 

    // Set the banner show flag depending on the special date flags above
    const [show, setShow] = useState(bannerName || isAutismAcceptanceMonth)

    // Return empty if no banner
    if (!show) {
        return null
    }

    // Happens during the April month
    const AutismAcceptanceMonth = () => {
        return (
            <div>
                <p id='navbar-banner-title' style={{ color: '#1e4d32'}}>Autism Awareness Month 🌍</p>
                <p>We celebrate neurodiversity along with thousands of families who use Hibi to support their loved one's care. <a style={{ color: '#1E4C32', fontWeight: 500, cursor: 'pointer', textDecoration: 'underline' }} href='https://onelink.to/gn5zbu'>Sign up for free today</a>.</p>
            </div>
        )

    }

    // Hiring for a new role
    const Hiring = () => {
        return (
            <div>
                <p id='navbar-banner-title' style={{ color: '#1e4d32'}}>We're hiring an engineer 🚀</p>
                <p>We're looking for a full-stack mobile engineer to join us. <a style={{ color: '#1E4C32', fontWeight: 500, cursor: 'pointer', textDecoration: 'underline' }} href='https://hibihealth.notion.site/Full-stack-Mobile-Developer-Hibi-780eb818aa2b4631814bc2d93b7a572c?pvs=4'>Apply now!</a></p>
            </div>
        )
    }

    return (
        <div className='navbar-banner' style={{ borderWidth: 4, borderColor: 'black' }}>
            {bannerName === 'hiring' ? <Hiring /> : isAutismAcceptanceMonth ? <AutismAcceptanceMonth /> : null}
            <img style={{ cursor: 'pointer' }} alt='Close' src={close} onClick={() => setShow(false)} />
        </div>
    )
}

const Navbar = ({ bannerName = null}) => {
    const [toggleMenu, setToggleMenu] = useState(false)

    return (
        <>
            <Banner bannerName={bannerName} />
            <div className='navbar'>
                <div style={{ position: 'absolute', left: '-2.25rem', height: '100%', width: '2.25rem', backgroundColor: 'white'}} />
                <div style={{ position: 'absolute', right: '-2.25rem', height: '100%', width: '2.25rem', backgroundColor: 'white'}} />
                <div className='navbar-links'>
                    <div className='navbar-links_logo'>
                        <Link to='/'>
                            <img loading='lazy' alt='Logo' src={hibi_logo_with_title} />
                        </Link>
                    </div>
                    <div className='navbar-links_container'>
                        <Menu />
                        <CTA />
                    </div>
                </div>
                <CTA />
                <div className='navbar-menu'>
                    {toggleMenu ? (
                        <RiCloseLine color='#757575' size={27} onClick={() => setToggleMenu(false)} />
                    ) : (
                        <RiMenu3Line color='#757575' size={27} onClick={() => setToggleMenu(true)} />
                    )}
                    <div
                        className={`navbar-menu_container ${
                            toggleMenu ? 'scale-up-center' : 'scale-down-center'
                        }`}
                    >
                        <div className='navbar-menu_container'>
                            <Menu />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Navbar
